export const FRONTEND_NUMBER_FORMAT = "dk";
export const BACKEND_NUMBER_FORMAT = "backend";
export const FRONTEND_PERCENT_FORMAT = "percent";

export const NumberFormatter = (number: string | number | undefined | null, numberFormat?: string, decimals = 0) => {
  
  if(number === undefined || number === null)
    return null
  if(typeof number == 'number')
    number = number.toString()

  switch (numberFormat?.toLocaleLowerCase()) {
    case "dk":
      number = parseFloat(number).toFixed(decimals);

      return new Intl.NumberFormat("da-DK", { maximumFractionDigits: decimals, minimumFractionDigits: decimals }).format(
        parseFloat(number),
      );

    case "percent":
      number = (parseFloat(number) * 100).toFixed(decimals);

      return new Intl.NumberFormat("da-DK", { maximumFractionDigits: decimals, minimumFractionDigits: decimals }).format(
        parseFloat(number),
      );

    case "backend":
      number = number.replace(",",".")
      number = (parseFloat(number)).toString();
      return new Intl.NumberFormat("en-US").format(
        parseFloat(number),
      );

    default:
      number = parseFloat(number).toFixed(decimals);
      return new Intl.NumberFormat("da-DK", { maximumFractionDigits: decimals, minimumFractionDigits: decimals }).format(parseFloat(number));
  }
};