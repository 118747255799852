import { Navigate, Outlet } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProtectedRoute = ({ isAllowed, children, redirectPath = "/" }: any) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
