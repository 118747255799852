import { TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";

interface InputFormProps {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  required?: boolean;
  type: React.HTMLInputTypeAttribute;
  errorText?: string;
  multiLine?: boolean;
  minRows?: number;
  placeholder?: string;
}

const InputForm = ({ name, required, control, label, type, errorText, multiLine, minRows, placeholder }: InputFormProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? errorText : null}
          fullWidth
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          label={label}
          variant="outlined"
          sx={{ m: 2 }}
          type={type}
          required={required}
          multiline={multiLine}
          minRows={minRows}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default InputForm;
