import { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box, Tab, Tabs, useTheme, Divider } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { SwitchModeButton } from "./SwitchModeButton";
import { useAuth } from "../hooks/useAuth";

const Navbar = () => {
  const { user, onLogout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [[currentRootPath]] = location.pathname.matchAll(/^(\/[^/]*)/g);
  const theme = useTheme();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onLogout();
    setAnchorEl(null);
  };

  const handleDarkModeSwitch = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="static"
        style={{ background: theme.palette.primary.main, color: theme.palette.text.secondary }}
        sx={{ background: "linear-gradient(90deg, rgba(47,112,14,1) 0%, rgba(24,142,31,1) 49%, rgba(47,112,14,1) 100%)", mb: 4 }}>
        <Toolbar>
          <Box
            component="img"
            sx={{
              width: 73,
            }}
            alt="The BioLens logo"
            src={theme.palette.mode === "dark" ? "/assets/logo-biolens-black-text.png" : "/assets/logo-biolens-white-text.png"}
          />
          <Box sx={{ flexGrow: 1 }}></Box>
          {!!user && user.role == "Customer" ? (
            <Box style={{ display: "flex", alignItems: "center", color: theme.palette.common.white }}>
              <Typography sx={{ pr: 1 }}>{user && user.name}</Typography>
              <IconButton
                id="more-button"
                aria-label="more"
                aria-controls={open ? "action-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                onClick={handleMenu}
                color="inherit">
                <AccountCircle color="inherit" />
              </IconButton>
              <Menu
                id="action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "more-button",
                }}
                color="inherit">
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem onClick={handleDarkModeSwitch}>
                  <SwitchModeButton />
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ display: "flex", justifyContent: "center", mb: 3 }} variant="dense">
        {!!user && user.role == "Customer" ? (
          <Tabs value={currentRootPath}>
            <Tab
              label="Certificates"
              value="/certificates"
              to="/certificates"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            {!!user && user.email.includes("customer") && (
              <Tab label="Reports" value="/report" to="/report" component={Link} sx={{ color: theme.palette.text.primary }} />
            )}
            {!!user && user.email.includes("customer") && (
              <Tab
                label=""
                icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
                sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
                disabled
              />
            )}
            <Tab
              label="Create order"
              value="/create-order"
              to="/create-order"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab label="Profile" value="/profile" to="/profile" component={Link} sx={{ color: theme.palette.text.primary }} />
          </Tabs>
        ) : (
          <Tabs value={currentRootPath}>
            <Tab label="Home" value="/" to="/" component={Link} sx={{ color: theme.palette.text.primary }} />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab label="About Biolens" value="/about" to="/about" component={Link} sx={{ color: theme.palette.text.primary }} />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab label="Contact Us" value="/contact" to="/contact" component={Link} sx={{ color: theme.palette.text.primary }} />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab
              label="Create order"
              value="/create-order"
              to="/create-order"
              component={Link}
              sx={{ color: theme.palette.text.primary }}
            />
            <Tab
              label=""
              icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
              sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
              disabled
            />
            <Tab label="Customer Login" value="/login" to="/login" component={Link} sx={{ color: theme.palette.text.primary }} />
            <Tab label="" sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }} disabled />
          </Tabs>
        )}
      </Toolbar>
    </>
  );
};

export default Navbar;
