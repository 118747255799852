import { Box, Container } from "@mui/material";
import Footer from "../components/Footer";

const Landingpage = () => {
  return (
    <Container>
      <Box mt={3} display="flex" gap={3} flexWrap="wrap" justifyContent="center">
        <Footer />
      </Box>
    </Container>
  );
};

export default Landingpage;
