import axios from "../services/axios";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../models/base";

export const useFetchCustomerCertificates = (customerId: string) => {
  return useQuery({
    queryKey: ["pos"],

    queryFn: async () => {
      const { data } = await axios.get(`${BASE_URL}/outgoingpos/customer/${customerId}`);
      return data.data;
    },
  });
};
