import { format } from "date-fns";

export const BACKEND_DATE_FORMAT = "yyyy-MM-dd";
export const FRONTEND_DATE_FORMAT = "dd-MM-yyyy";
export const FRONTEND_MONTH_FORMAT = "MMMM yyyy";
export const FRONTEND_SHORT_MONTH_FORMAT = "MMM yyyy";
export const FRONTEND_READABLE_DAY_WITH_YEAR = "d MMM yyyy";
export const FRONTEND_READABLE_DAY = "d MMM";

export const DateFormatter = (date: (Date|string), dateFormat = BACKEND_DATE_FORMAT) => {
  if(date instanceof(Date)) {
    return format(date as Date, dateFormat);
  }

  return format(new Date(date), dateFormat);
};
