/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import useLocalStorage from "./useLocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../services/axios";
import { REDIRECT_URI, BASE_URL } from "../services/base";
import { useMsal } from "@azure/msal-react";

interface IAuthContext {
  isAuthenticated: boolean;
  user: User | null;
  showError: boolean;
  onLogout: () => void;
  setAndGetUserFromBackend: (id: string, loginRequest: string) => void;
}

const initialConfig = {
  isAuthenticated: localStorage.getItem("user") !== null && localStorage.getItem("user") !== "error",
  user: null,
  showError: false,
  onLogout: () => {},
  setAndGetUserFromBackend: () => {},
};

const AuthContext = createContext<IAuthContext>(initialConfig);

export interface User {
  id: string;
  email: string;
  name: string;
  role: string;
  plantId?: string;
  customerId?: string;
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [localStorageUser, setLocalStorageUser] = useLocalStorage("user", null);
  const [isAuthenticated] = useState(() => {
    return localStorageUser !== null && localStorageUser != "error";
  });
  const [user, setUser] = useState<User | null>(null);
  const [showError, setShowError] = useState(false);
  const { instance } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      // REFRESH
      setUser(localStorageUser);
      navigate(`${location.pathname}`);
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (location.pathname == "/login") {
        if (user.role === "Customer") navigate("/certificates");
      }
    }
  }, [user]);

  const fetchUser = async (id: string) => {
    try {
      const { data } = await axios.get(`${BASE_URL}/users/${id}`);
      return data.data;
    } catch (err) {
      setShowError(true);
      setUser(null);
      setLocalStorageUser(null);
      return;
    }
  };

  const setAndGetUserFromBackend = async (id: string, loginRequest: string) => {
    const usr = await fetchUser(id);
    if (loginRequest == "Customer" && !usr.customerId) {
      setShowError(true);
      setUser(null);
      setLocalStorageUser("error");
      return;
    }
    usr.role = loginRequest;
    setUser(usr);
    setLocalStorageUser(usr);
  };

  const handleLogout = async () => {
    setLocalStorageUser(null);
    instance.logoutRedirect({ postLogoutRedirectUri: `${REDIRECT_URI}/login` });
  };

  const value = {
    isAuthenticated,
    user,
    showError,
    onLogout: handleLogout,
    setAndGetUserFromBackend,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
