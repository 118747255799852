/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "../../services/axios";
import { useState } from "react";
import { CloudDownload, MoreVert, PictureAsPdf } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import {  DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../models/base";
import { DateFormatter, FRONTEND_DATE_FORMAT } from "../../utils/DateFormatter";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";


const CertificatesTable = ({ data }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const onDownload = async (format: string) => {
    const url = `${BASE_URL}/outgoingpos/${selectedRow.row.id}/documents/${format}`;
    const { data } = await axios.get(url);
    const link = document.createElement('a');
    link.href = data.data;
    link.download = `${selectedRow.row.uniqueNumber}.${format}`;
    link.click();
  };

  const handleMenuOptions = () => {
    return (
      <Box>
        <IconButton
          id="more-button"
          aria-label="more"
          aria-controls={open ? "more-action-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          onClick={handleMenu}>
          <MoreVert />
        </IconButton>
        <Menu id="more-action-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => onDownload("xlsx")}>
            <CloudDownload sx={{ mr: 1 }} color="primary" />
            Download as Excel
          </MenuItem>
          <MenuItem onClick={() => onDownload("pdf")}>
            <PictureAsPdf sx={{ mr: 1 }} color="primary" />
            Download as PDF
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      <DataGrid
        rows={data}
        columns={[
          {
            field: "uniqueNumber",
            headerName: "PoS Number",
            width: 250,
            editable: false,
            renderCell: (params) => (
              <Typography variant="caption">
                <strong>{params.value}</strong>
              </Typography>
            ),
          },
          {
            field: "incomingPosQuantityKwh",
            headerName: "Total amount ",
            type: "number",
            width: 120,
            editable: false,
            renderCell: (params) => {
              const totalSumMWh = params.row.incomingPosData.reduce(
                (accumulator: number, currentValue: any) => accumulator + currentValue.quantityKwh,
                0,
              );
              const displayedAmount = Math.trunc(totalSumMWh / 1000);

              return (
                <Box display="flex">
                  <Typography variant="caption" color={theme.palette.warning.main} sx={{ mr: 1 }}>
                    <strong>{NumberFormatter(displayedAmount, FRONTEND_NUMBER_FORMAT)} </strong>
                  </Typography>
                  <Typography variant="caption">MWh</Typography>
                </Box>
              );
            },
          },
          {
            field: "plantName",
            headerName: "Plant",
            width: 100,
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "productionPeriodStart",
            headerName: "Period Start",
            type: "date",
            width: 120,
            editable: false,
            renderCell: (params) => (
              <Box>
                <Typography variant="caption">{DateFormatter(params.value, FRONTEND_DATE_FORMAT)}</Typography>
              </Box>
            ),
          },
          {
            field: "productionPeriodEnd",
            headerName: "Period End",
            type: "date",
            width: 120,
            editable: false,
            renderCell: (params) => (
              <Box>
                <Typography variant="caption">{DateFormatter(params.value, FRONTEND_DATE_FORMAT)}</Typography>
              </Box>
            ),
          },
          {
            field: "actions",
            headerName: "",
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            align: "right",
            filterable: false,
            renderCell: () => handleMenuOptions(),
          },
        ]}
        pageSize={25}
        rowsPerPageOptions={[25]}
        density="compact"
        hideFooterSelectedRowCount
        onRowClick={(params) => setSelectedRow(params)}
        isRowSelectable={() => false}
        sx={{
          border: 0,
          [`& .MuiDataGrid-cell `]: {
            borderBottom: "none",
            "&:focus-within": {
              outline: "none",
            },
          },
          [`& .Mui-selected `]: {
            background: theme.palette.mode === "dark" ? "#3d6b3f !important" : "none",
          },
          [`& .MuiDataGrid-row `]: {
            background: theme.palette.mode === "dark" ? "#333333" : "none",
            my: 0.3,
            borderRadius: 1,
            [`&:hover`]: {
              background: theme.palette.mode === "dark" ? "#333333 !important" : "#f5f5f5 !important",
            },
          },
          [`& .MuiDataGrid-columnSeparator `]: {
            display: "none",
          },
        }}
      />
    </Box>
  );
};

export default CertificatesTable;
