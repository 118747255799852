import { Box, CircularProgress, Paper, Typography } from "@mui/material";

const LoadingSpinner = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
      <Paper
        elevation={0}
        sx={{ p: 2, my: 4, width: 400, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 3 }}>
          Loading ...
        </Typography>
      </Paper>
    </Box>
  );
};

export default LoadingSpinner;
