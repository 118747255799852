import { Box, Container, Divider, Typography, useTheme } from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import CertificatesTable from "../../components/tables/CertificatesTable";
import { useFetchCustomerCertificates } from "../../hooks/incomingPos";
import { useAuth } from "../../hooks/useAuth";

const Certificates = () => {
  const { user } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion
  const { data, isLoading, isError } = useFetchCustomerCertificates(user!.customerId!);
  const theme = useTheme();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Bought Certificates/PoS
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ mt: 2 }}>{data ? <CertificatesTable data={data} /> : "No data were found"}</Box>
    </Container>
  );
};

export default Certificates;
