/* eslint-disable react/jsx-no-undef */
import { Box, Container } from "@mui/material";
import Footer from "../../components/Footer";

const AboutUs = () => {
  return (
    <Container>
      <Box mt={3} display="flex" gap={3} flexWrap="wrap" justifyContent="center">
        <Box maxWidth={1200}>
          <h2> What is Biolens? </h2>
          <p>
            BIOLENS is a tool to track Green gas certificates which are sold to our customers. Customers can be directly a consumer or a
            third party to conduct a further sale.
          </p>
          <h2> What is Green Gas? </h2>
          <p>
            Green gas also known as Biogas consists of methane (CH4) and carbon dioxide (CO2). Biogas is produced when biomass are fed into
            Biogas plants, which consists of a series of tanks without oxygen. The biomass used comes primarily from livestock manure but
            can also consist of residues from the food industry, including slaughterhouse waste, and other sources such as household waste.
            In addition, residues from agriculture are used, including straw.
          </p>
          <h2>Why Green Gas Certificate is required?</h2>
          <p>
            Green gas certificates/Proof of Sustainability (PoS) are a method of assigning green attributes to Biomethane, allowing
            customers to ensure their gas consumption is sustainably produced. In addition, it gives Biomethane producers a way of obtaining
            an extra income to support their operations. Generally, the European Biomethane certificates are valid for one year except for
            UK RGGOs (Renewable Gas Guarantees of Origin) where validation lasts for three years three months.
          </p>
        </Box>
        <Footer />
      </Box>
    </Container>
  );
};

export default AboutUs;
