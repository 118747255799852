import { ThemeOptions } from "@mui/material";

export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    background: {
      default: "#f9f9f9",
    },
    primary: {
      main: "#269946",
    },
    secondary: {
      main: "#6E15FF",
    },
    text: {
      primary: "#1e1e1e",
    },
    common: {
      white: "#f9f9f9",
    },
  },
};
