import axios from "../services/axios";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../models/base";
import { ICustomer } from "../models/Interfaces";

export const useFetchCustomer = (customerId: string) => {
  return useQuery<ICustomer>({
    queryKey: ["customer", customerId],
    queryFn: async () => {
      const { data } = await axios.get(`${BASE_URL}/customers/${customerId}`);
      return data.data;
    },
  });
};
