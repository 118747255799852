import axios from "../services/axios";
import { useMutation } from "@tanstack/react-query";
import { BASE_URL } from "../models/base";
import { IOrderRequest } from "../models/Interfaces";

export const useCreateOrderRequestAsCustomer = () => {
  return useMutation({
    mutationFn: async (newOrderRequest: IOrderRequest): Promise<IOrderRequest> =>
      (await axios.post(`${BASE_URL}/order`, newOrderRequest)).data.data,
  });
};

export const useCreateOrderRequest = () => {
  return useMutation({
    mutationFn: async (newOrderRequest: IOrderRequest): Promise<IOrderRequest> =>
      (await axios.post(`${BASE_URL}/order/anonymous`, newOrderRequest)).data.data,
  });
};
