import { Control } from "react-hook-form";
import { IOrderRequest } from "../../models/Interfaces";
import { Box } from "@mui/material";
import InputText from "../../components/form/InputText";

type CreateOrderFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IOrderRequest, any>;
};

const CreateOrderForm = ({ control }: CreateOrderFormProps) => {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <InputText
          name="contactName"
          control={control}
          label="Contact name"
          required
          errorText={"Contact name is required"}
          type={"text"}
        />
        <InputText name="email" control={control} label="Email" required errorText={"email is required"} type={"text"} />
        <InputText
          name="phoneNumber"
          control={control}
          label="Phone number"
          required
          errorText={"Phone number is required"}
          type={"text"}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <InputText name="contractReference" control={control} label="Contract reference" type={"number"} />
        <InputText
          name="gramCo2EqPerMegaJoule"
          control={control}
          label="CO2-eq g/MJ"
          errorText={"CO2-eq g/MJ is required"}
          type={"number"}
        />
        <InputText
          name="quantityKwh"
          control={control}
          label="Energy content(GCV), MWh"
          errorText={"Energy content is required"}
          type={"number"}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <InputText
          name="specification"
          control={control}
          label="Other specifications"
          type="string"
          multiLine
          minRows={3}
          placeholder={"Please specify your order request with data such as red certification, type and so on"}
        />
      </Box>
    </>
  );
};

export default CreateOrderForm;
