/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Alert, Avatar, Box, Button, CircularProgress, Container, Paper, Typography, useTheme } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../services/msal";
import { REDIRECT_URI } from "../services/base";
import Footer from "./Footer";

export default function SignIn() {
  const isAuthenticated = useIsAuthenticated();
  const [loginError, setLoginError] = useState(false);
  const { showError: authError, setAndGetUserFromBackend } = useAuth();
  const { instance } = useMsal();
  const theme = useTheme();

  useEffect(() => {
    if (authError) {
      localStorage.setItem("loginError", "true");
      instance.logout();
    }
  }, [authError]);

  useEffect(() => {
    if (localStorage.getItem("loginError") == "true") {
      setLoginError(true);
      localStorage.removeItem("loginError");
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && instance.getAllAccounts().length > 0) {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      setAndGetUserFromBackend(instance.getAllAccounts()[0].username, "Customer");
    }
  }, [isAuthenticated]);

  const handleCustomerAzureLogin = () => {
    instance.loginRedirect({ ...loginRequest, redirectUri: `${REDIRECT_URI}/login` }).catch((e) => {
      console.log(e);
    });
  };

  return (
    <Container component="main">
      <Box display="flex" flexDirection="column" alignItems="center" width="100%" mb={10}>
        <Avatar sx={{ mb: 1, bgcolor: theme.palette.primary.main }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          BioLens
        </Typography>
        <Box mt={3} display="flex" gap={3} flexWrap="wrap" justifyContent="center">
          <Paper elevation={1} sx={{ p: 2, width: 400, height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <AuthenticatedTemplate>
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5" sx={{ p: 2, mb: 2 }} color={theme.palette.primary.main} align="center">
                  BioLens Customer
                </Typography>

                <Box>
                  <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                    <Button onClick={() => handleCustomerAzureLogin()} variant="contained" color="primary">
                      Log in
                    </Button>
                  </Box>
                </Box>
              </Box>
            </UnauthenticatedTemplate>
          </Paper>
        </Box>
        <Box mt={3}>{loginError ? <Alert severity="error">{`You don't have right permission to see the content.`}</Alert> : ""}</Box>
        <Footer />
      </Box>
    </Container>
  );
}
