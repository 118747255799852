import { Box, Container, Divider, Typography, useTheme } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { useFetchCustomer } from "../../hooks/customer";
import LoadingSpinner from "../../components/LoadingSpinner";

const Reports = () => {
  const { user } = useAuth();
  const theme = useTheme();

  const { data: customer, isLoading } = useFetchCustomer(user?.customerId as string);

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" color={"primary"} sx={{ flexGrow: 1 }}>
          Power BI Reports
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      {isLoading && <LoadingSpinner />}
      {!isLoading && customer?.powerBiUrl && (
        <Box sx={{ mt: 2 }}>
          <iframe
            id="powerBI"
            title="Power BI Report"
            width="100%"
            height="776"
            style={{
              aspectRatio: "4/3",
              border: "unset",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            src={customer.powerBiUrl}
          />
        </Box>
      )}

      {!isLoading && !customer?.powerBiUrl && (
        <Typography variant="h6" color={"primary"} textAlign="center">
          Missing PowerBi link
        </Typography>
      )}
    </Container>
  );
};

export default Reports;

//height="810"
// border: "1px solid #e0e0e0",
