import { useForm } from "react-hook-form";
import { IOrderRequest } from "../../models/Interfaces";
import { useAuth } from "../../hooks/useAuth";
import { Box, Button, CircularProgress, Container, Divider, Typography, useTheme } from "@mui/material";
import { useCreateOrderRequest, useCreateOrderRequestAsCustomer } from "../../hooks/order";
import { toast } from "react-toastify";
import CreateOrderForm from "./CreateOrderForm";
import { useEffect } from "react";

const defaultValues = {
  contactName: "",
  phoneNumber: "",
  email: "",
  gramCo2EqPerMegaJoule: 0,
  quantityKwh: 0,
  specification: null,
  contractReference: null,
};

const CreateOrder = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const methods = useForm<IOrderRequest>({ defaultValues });
  const { handleSubmit, control, reset } = methods;
  const { mutate: createOrderRequestWithCustomer, isPending: isPendingMutationWithCustomer } = useCreateOrderRequestAsCustomer();
  const { mutate: createOrderRequest, isPending } = useCreateOrderRequest();

  useEffect(() => {
    if (user) {
      reset({ ...defaultValues, contactName: user.name, email: user.email });
    }
  }, [user, reset]);

  const resetForm = () => {
    if (user) {
      reset({ ...defaultValues, contactName: user.name, email: user.email });
    } else {
      reset(defaultValues);
    }
  };

  const onSubmit = (values: IOrderRequest) => {
    const payload: IOrderRequest = {
      ...values,
      specification: values.specification ? values.specification : null,
      contractReference: values.contractReference ? values.contractReference : null,
    };

    if (user) {
      createOrderRequestWithCustomer(payload, {
        onSuccess() {
          toast.success("Request successfully sent!");
          resetForm();
        },
        onError(error) {
          console.error(error);
        },
      });
    } else {
      createOrderRequest(payload, {
        onSuccess() {
          toast.success("Request successfully sent!");
          resetForm();
        },
        onError(error) {
          console.error(error);
        },
      });
    }
  };

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Order
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ p: 2, my: 2 }}>
        <Typography variant="h6">Create order request</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
          <CreateOrderForm control={control} />
          <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
          <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
            <Button variant="text" color="secondary" onClick={() => resetForm()}>
              Cancel
            </Button>
            {isPending || isPendingMutationWithCustomer ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button variant="contained" color="success" type="submit">
                Submit
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default CreateOrder;
